import { createMuiTheme, Color } from '@material-ui/core';
import { orange, indigo } from '@material-ui/core/colors';

const primaryPalette = orange;
const secondaryPalette = indigo;

export const theme = createMuiTheme({
  palette: {
    // if changing the following also _colors.scss should be adapted
    primary: primaryPalette,
    secondary: secondaryPalette,
  },
  typography: {
    fontFamily: ['Nunito', 'sans-serif'].join(','),
  },
});

export function getPrimaryColorPalette() {
  return (Object.keys(primaryPalette) as Array<keyof Color>).map(
    key => primaryPalette[key]
  );
}
