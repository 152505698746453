import React, { Component } from 'react';
import { withStyles, StyleRules } from '@material-ui/core/styles';
import { Grid, Typography, Link } from '@material-ui/core';
import IProductSearchResult from '../../../functions/src/models/ProductSearchResult';
import IProduct from '../../../functions/src/models/Product';
import { createFadeInStyles } from './utils/FadeInAnimation';
import PriceCategory from './searchResults/PriceCategory';
import { ProductCategory, getCategoryName } from './searchResultsChart/models';
import { scrollService } from '../services';
import { ExplanationSectionId } from './SearchResultsVisualizationSection';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const styles: StyleRules = {
  container: {
    maxWidth: '100%',
  },
  result: {
    padding: '10px',
  },
  chart: {
    marginTop: '50px',
    height: '300px',
    width: '100%',
  },
  captionIcon: {
    padding: '0 8px',
  },
};

interface IProps {
  classes: { [key: string]: string };
  searchQuery: string;
  searchResults: IProductSearchResult;
}

class SearchResults extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      priceCategories: [
        {
          id: 'cheap',
          name: getCategoryName('cheap'),
          winner: props.searchResults.products.cheap[0],
          products: props.searchResults.products.cheap,
        },
        {
          id: 'choice',
          name: getCategoryName('choice'),
          winner: props.searchResults.products.choice[0],
          products: props.searchResults.products.choice,
        },
        {
          id: 'crazy',
          name: getCategoryName('crazy'),
          winner: props.searchResults.products.crazy[0],
          products: props.searchResults.products.crazy,
        },
      ],
      allProducts: [
        ...props.searchResults.products.cheap,
        ...props.searchResults.products.choice,
        ...props.searchResults.products.crazy,
      ],
    };
  }

  state: {
    priceCategories: Array<{
      id: ProductCategory;
      name: string;
      winner: IProduct;
      products: IProduct[];
    }>;
    allProducts: IProduct[];
  };

  scrollToExplanation() {
    scrollService.scrollToElementWithId(ExplanationSectionId);
  }

  render() {
    const { classes, searchResults, searchQuery } = this.props;
    return (
      <div>
        <Typography style={{ color: 'white' }} variant="h6" paragraph={true}>
          Wir haben{' '}
          <Typography
            color="primary"
            variant="inherit"
            style={{ display: 'inline' }}
          >
            {searchResults.totalProducts.toLocaleString()}
          </Typography>{' '}
          Produkte für Ihre Suchanfrage gefunden, diese nach Preis gruppiert und
          in jeder Kategorie einen Sieger gekürt!
          <Link
            component="button"
            onClick={this.scrollToExplanation}
            className={classes.captionIcon}
          >
            <HelpOutlineIcon></HelpOutlineIcon>
          </Link>
        </Typography>
        <Grid container justify="center" className={classes.container}>
          {this.state.priceCategories.map((priceCategory, index) => (
            <Grid item key={index} xs={12} md={4} className={classes.result}>
              {!!priceCategory.winner && (
                <PriceCategory
                  id={priceCategory.id}
                  name={priceCategory.name}
                  winner={priceCategory.winner}
                  products={priceCategory.products}
                  allProducts={this.state.allProducts}
                  searchQuery={searchQuery}
                  style={createFadeInStyles(index)}
                  rank123={index === 1 ? 1 : index === 0 ? 2 : 3} // winner's podium: middle is first, left second and right third place
                />
              )}
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(SearchResults);
