class ScrollService {
  private constructor() {}
  public static instance = new ScrollService();

  scrollToElementWithId(elementId: string) {
    this.scrollToElement(`#${elementId}`);
  }

  private scrollToElement(selector: string) {
    // https://stackoverflow.com/questions/3163615/how-to-scroll-html-page-to-given-anchor
    const element = document.querySelector(selector);
    if (element) {
      const top = element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }
  }
}

export const scrollService = ScrollService.instance;
