import { History } from 'history';

export function navigateTo(absolutePath: string[], history: History) {
  const pathname =
    '/' +
    absolutePath
      .filter(x => !!x)
      .map(x => encodeURIComponent(x))
      .join('/') +
    window.location.search; // preserve query parameters for preserving debug settings etc.

  if (history.location.pathname != pathname) {
    history.push(pathname);
  }
}
