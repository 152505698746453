import React, { Component } from 'react';
import { Link } from '@material-ui/core';
import IProduct from '../../../../functions/src/models/Product';

interface IProps {
  product: IProduct;
  priceCategory: string;
  trackingOrigin: string;

  className?: string;
}

class AmazonLink extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const { product, priceCategory, trackingOrigin, className } = this.props;

    return (
      <Link
        target="_blank"
        rel="noopener"
        href={product.url}
        underline="none"
        className={`ccc-tracking-affiliate-link ${className}`}
        data-price={product.price}
        data-category={priceCategory}
        data-origin={trackingOrigin}
      >
        {this.props.children}
      </Link>
    );
  }
}

export default AmazonLink;
