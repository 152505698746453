import React, { Component, CSSProperties } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid, Button, Theme } from '@material-ui/core';
import SearchResultsDialog from '../SearchResultsDialog';
import IProduct from '../../../../functions/src/models/Product';
import ReviewsBox from '../searchResults/ReviewsBox';
// import SalesRankIndicator from '../searchResults/SalesRankIndicator';
// import ReleaseDateIndicator from '../searchResults/ReleaseDateIndicator';
import AmazonButton from './AmazonButton';
// import { Circle } from 'rc-progress';
import { CCCColor } from '../../styles/colors';
import ProductPrice from './ProductPrice';
// import AppSettings from '../../../AppSettings';
import {
  ProductCategory,
  getCategoryColor,
} from '../searchResultsChart/models';
import { StyleRulesCallback } from '@material-ui/styles/withStyles';
import Truncate from 'react-truncate';
import { getPriceRange } from '../../utils/ProductUtils';
import FeatureBadge from '../core/FeatureBadge';
import AmazonLink from './AmazonLink';
import { formatAsCurrency } from '../core/format';
import { youTubeService, IYouTubeVideo } from '../../services/YouTubeService';
import { YouTubeButton } from '../core';
import { YouTubeVideosDialog } from '../core';

const styles: StyleRulesCallback<Theme, {}> = theme => ({
  resultContent: {
    color: CCCColor.TextColorDark,
    padding: '10px',
    maxWidth: 1400 / 3, // should be in line with max content width defined in App.scss (TODO: make dependency explicit)
    margin: 'auto',
    [theme.breakpoints.up('sm')]: {
      padding: '20px',
    },
    position: 'relative',
    overflow: 'hidden',
  },
  resultContentRank1: {},
  resultContentRank2: {
    marginTop: '35px',
  },
  resultContentRank3: {
    marginTop: '70px',
  },
  resultCategoryName: {
    position: 'absolute',
    backgroundColor: CCCColor.Primary,
    width: '300px',
    height: '50px',
    lineHeight: '50px',
    fontSize: '20px',
    color: '#fff',
    transform: 'rotate(-45deg)',
    left: '-96px',
    top: '10px',
    fontWeight: 'normal',
    boxShadow:
      '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
  },
  resultImage: {
    width: '100%',
    height: '180px',
    margin: 'auto',
    marginTop: '40px',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  resultTitle: {
    display: 'table',
    width: '100%',
    height: 100,
    marginTop: '8px',
    marginBottom: 0,
  },
  resultTitleContent: {
    display: 'table-cell',
    verticalAlign: 'middle',
    color: CCCColor.Blue,
    fontSize: '18px',
    textDecoration: 'none',
    fontStyle: 'italic',
  },
  resultCounter: {
    margin: 5,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 70,
    position: 'relative',
  },
  resultCounterContent: {
    position: 'absolute',
    top: 'calc(50% - 18px)',
    width: '100%',
    fontSize: '30px',
    lineHeight: '30px',
  },
  resultDetails: {
    fontSize: '15px',
    textAlign: 'left',
    height: '180px',
    overflow: 'hidden',
  },
  resultFeatures: {
    height: '250px',
    overflowY: 'auto',
    textAlign: 'left',
    fontSize: '0.85em',
  },
  resultFeaturesRank1: {},
  resultFeaturesRank2: {
    height: '215px',
  },
  resultFeaturesRank3: {
    height: '180px',
  },
});

// /**
//  * Will emit a value each 50ms until reaching the target value.
//  * Maps values to 0-100 range interpreting the smallest value as 100.
//  */
// function getPercentValueInvertedCounter(value: number, allValues: number[]) {
//   const max = Math.max(...allValues);
//   const targetValue = Math.round(((max - value) / max) * 100);
//   let currentValue = 0;
//   return interval(10).pipe(
//     map(() => currentValue++),
//     takeWhile(value => value < targetValue)
//   );
// }

// function getCounterColor(value: number) {
//   return value < 33 ? 'red' : value < 66 ? 'yellow' : 'green';
// }

interface IProps {
  id: ProductCategory;
  name: string;
  winner: IProduct;
  products: IProduct[];
  allProducts: IProduct[];
  searchQuery: string;
  rank123: number;

  style: CSSProperties;
  classes: { [key: string]: string };
}

class PriceCategory extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  state: {
    listOpen: boolean;
    youTubeVideos: IYouTubeVideo[];
    youTubeVideosOpen: boolean;
    relevanceRankCounter: number;
    salesRankCounter: number;
    reviewRankCounter: number;
  } = {
    listOpen: false,
    youTubeVideosOpen: false,
    youTubeVideos: [],
    relevanceRankCounter: 0,
    salesRankCounter: 0,
    reviewRankCounter: 0,
  };

  openList() {
    this.setState({ listOpen: true });
  }
  closeList() {
    this.setState({ listOpen: false });
  }

  openYouTubeVideosDialog() {
    this.setState({ youTubeVideosOpen: true });
  }
  closeYouTubeVideosDialog() {
    this.setState({ youTubeVideosOpen: false });
  }

  componentDidMount() {
    this.initProgressCounters();
    youTubeService
      .searchForVideos(this.props.winner.name)
      .then(videos => this.setState({ youTubeVideos: videos }));
  }

  initProgressCounters() {
    const { winner, products } = this.props;
    function calcPercent(property: string) {
      const value = (winner as any)[property];
      const max = Math.max(...products.map((p: any) => p[property]));
      return Math.round(((max - value) / max) * 100);
    }

    this.setState({
      relevanceRankCounter: calcPercent('relevanceRank'),
      salesRankCounter: calcPercent('salesRank'),
      reviewRankCounter: calcPercent('reviewRank'),
    });

    // TODO: fix progress counters performance & enable again
    // const allRelevanceRanks = this.state.allProducts.map(s => s.relevanceRank);
    // const allSalesRanks = this.state.allProducts.map(s => s.salesRank);
    // const allReviewRanks = this.state.allProducts.map(s => s.reviewRank);
    // this.state.priceCategories.forEach((priceCategory, ind) => {
    //   getPercentValueInvertedCounter(
    //     priceCategory.winner.relevanceRank,
    //     allRelevanceRanks
    //   )
    //     .pipe(delay(ind * 1000))
    //     .subscribe(value => {
    //       priceCategory.relevanceRankCounter = value;
    //       this.forceUpdate();
    //     });

    //   getPercentValueInvertedCounter(priceCategory.winner.salesRank, allSalesRanks)
    //     .pipe(delay(ind * 1000))
    //     .subscribe(value => {
    //       priceCategory.salesRankCounter = value;
    //       this.forceUpdate();
    //     });

    //   getPercentValueInvertedCounter(priceCategory.winner.reviewRank, allReviewRanks)
    //     .pipe(delay(ind * 1000))
    //     .subscribe(value => {
    //       priceCategory.reviewRankCounter = value;
    //       this.forceUpdate();
    //     });
    // });
  }

  render() {
    const {
      id,
      name,
      winner,
      products,
      allProducts,
      searchQuery,
      style,
      classes,
    } = this.props;

    const priceRange = getPriceRange(products);

    return (
      <Paper
        elevation={1}
        className={
          classes.resultContent +
          ' ' +
          classes['resultContentRank' + this.props.rank123]
        }
        style={style}
      >
        <h2
          className={classes.resultCategoryName}
          style={{ backgroundColor: getCategoryColor(id) }}
        >
          {name}
        </h2>

        <Grid container direction="row" justify="space-around">
          <Grid item xs={8} md={12} lg={12} xl={8}>
            <AmazonLink
              product={winner}
              priceCategory={name}
              trackingOrigin="amazon-iconlink"
            >
              <span
                className={classes.resultImage}
                style={{
                  display: 'inline-block',
                  backgroundImage: `url('${winner.image}')`,
                }}
              />
            </AmazonLink>
          </Grid>

          <Grid
            item
            xs={4}
            md={12}
            lg={12}
            xl={4}
            container
            direction="column"
            justify="center"
            alignItems="flex-end"
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className="ccc-margin-top-bottom-2"
            >
              <Grid item xs={12} lg={6} xl={12} className="ccc-margin-top-3">
                <FeatureBadge text="Bestseller" />
              </Grid>
              <Grid item className="ccc-margin-top-3" xs={12} lg={6} xl={12}>
                <ProductPrice product={winner} />
              </Grid>
              {/* <Grid item>
                <SalesRankIndicator
                  product={winner}
                  allProducts={allProducts}
                  searchQuery={searchQuery}
                  large={true}
                />
              </Grid>
              {AppSettings.searchResults.showReleaseDateIndicator &&
                winner.details.releaseDate && (
                  <Grid item>
                    <ReleaseDateIndicator
                      product={winner}
                      allProducts={allProducts}
                    />
                  </Grid>
                )}
              {winner.reviewRank && (
                <Grid item>
                  Review rank
                  <div className={classes.resultCounter}>
                    <Circle
                      percent={this.state.reviewRankCounter}
                      strokeWidth="4"
                      strokeColor={getCounterColor(
                        this.state.reviewRankCounter
                      )}
                    />
                    <div className={classes.resultCounterContent}>
                      {winner.reviewRank}
                    </div>
                  </div>
                </Grid>
              )} */}
            </Grid>
          </Grid>
        </Grid>

        <p className={`${classes.resultTitle}`}>
          <AmazonLink
            product={winner}
            priceCategory={name}
            trackingOrigin="amazon-textlink"
            className={classes.resultTitleContent}
          >
            <Truncate lines={3}>{winner.name}</Truncate>
          </AmazonLink>
        </p>

        {winner.reviews && (
          <div>
            <ReviewsBox
              iFrameUrl={winner.reviews.iFrameUrl}
              detailsUrl={winner.reviews.url}
            />
          </div>
        )}

        {winner.details && winner.details.features && (
          <div
            className={
              classes.resultFeatures +
              ' ' +
              classes['resultFeaturesRank' + this.props.rank123]
            }
          >
            <ul>
              {winner.details.features.map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
            </ul>
          </div>
        )}

        <div className="ccc-margin-top-3">
          <YouTubeButton
            numberOfVideos={this.state.youTubeVideos.length}
            onClick={() => this.openYouTubeVideosDialog()}
            disabled={this.state.youTubeVideos.length < 1}
          />
          <YouTubeVideosDialog
            videos={this.state.youTubeVideos}
            open={this.state.youTubeVideosOpen}
            onClose={() => this.closeYouTubeVideosDialog()}
          />
        </div>

        <Grid
          container
          direction="column"
          justify="space-around"
          spacing={1}
          className="ccc-margin-top-2"
        >
          <Grid item>
            <AmazonButton
              product={winner}
              priceCategory={name}
              className="ccc-margin-top-2"
            />
          </Grid>
          <Grid item>
            <Button
              variant="text"
              color="primary"
              onClick={() => this.openList()}
            >
              Weitere Produkte
              <br />
              {formatAsCurrency(priceRange.min)} -{' '}
              {formatAsCurrency(priceRange.max)}
            </Button>
          </Grid>
        </Grid>

        <SearchResultsDialog
          priceCategory={name}
          products={products}
          allProducts={allProducts}
          searchQuery={searchQuery}
          open={this.state.listOpen}
          onClose={() => this.closeList()}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(PriceCategory);
