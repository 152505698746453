import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Link } from '@material-ui/core';

const styles = {
  main: {
    height: '50px',
    lineHeight: '50px',
    color: '#fff',
    textAlign: 'center',
    fontSize: '12px',
  } as CSSProperties,
  item: {
    padding: '0 10px',
    '&:not(:last-child)': {
      borderRight: '1px solid #fff',
    },
  },
};

interface IProps {
  classes: { [key: string]: string };
}

class Footer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.main}>
        <span className={classes.item}>from Munich with &#x2764;&#xFE0F;</span>
        <span className={classes.item}>
          <Link href="https://cms.etalgo.com/impressum/" target="_blank">
            Impressum
          </Link>
        </span>
        <span className={classes.item}>
          <Link href="https://cms.etalgo.com/datenschutz/" target="_blank">
            Datenschutzerklärung
          </Link>
        </span>
        <span className={classes.item}>&copy; 2019 BrainChimps.com</span>
      </div>
    );
  }
}

export default withStyles(styles)(Footer);
