import React, { Component, ReactNode } from 'react';
import { Grid, StyleRulesCallback, withStyles, Theme } from '@material-ui/core';
import { CCCColor } from '../styles/colors';

interface IProps {
  children: {
    content: ReactNode;
    description: ReactNode;
  };
  classes: { [key: string]: string };
  /** Set true to move content to the right and description to the left. */
  contentRight?: boolean;
}

const styles: StyleRulesCallback<Theme, {}> = theme => ({
  item: {
    padding: '20px 10px',
    [theme.breakpoints.up('md')]: {
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  itemInverted: {
    [theme.breakpoints.up('md')]: {
      '&:nth-of-type(1)': {
        order: 2,
      },
    },
  },
  section: {
    padding: '60px 0 0 0',
  },
  content: {
    backgroundColor: CCCColor.White,
    padding: '20px 10px',
    boxShadow:
      '0 15px 35px rgba(90,102,120,.1), 0 5px 15px rgba(90,102,120,.1)',
    [theme.breakpoints.up('sm')]: {
      padding: '40px 40px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '80px 20px',
    },
  },
  description: {
    maxWidth: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: '20px 10px',
    [theme.breakpoints.up('md')]: {
      padding: '0 60px',
    },
  },
});

class Section extends Component<IProps> {
  render() {
    const { children, classes, contentRight = false } = this.props;

    let itemClass = classes.item;
    if (contentRight) {
      itemClass += ` ${classes.itemInverted}`;
    }
    return (
      <Grid container className={classes.section}>
        <Grid item xs={12} sm={12} md={6} className={itemClass}>
          <div className={classes.content}>{children.content}</div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={itemClass}>
          <div className={classes.description}>{children.description}</div>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(Section);
