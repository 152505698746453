import { theme } from './theme';

export const CCCColor = {
  Primary: theme.palette.primary.main,
  Secondary: theme.palette.secondary.main,
  Blue: '#039be5',
  Orange: '#fdb44b',
  Red: '#ea4c4c',
  Green: '#0c907d',
  TextColorDark: '#282c34',
  White: '#fff',
  GreyedOut: '#ddd',
  Grey: '#eff2f5',
  YouTubeRed: '#ff0000',
};
