import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { PlayArrow } from '@material-ui/icons';
import { CCCColor } from '../../styles/colors';

interface IProps {
  disabled?: boolean;
  numberOfVideos: number;
  onClick: () => void;
}

const useStyles = makeStyles({
  button: {
    textTransform: 'none',
    backgroundColor: CCCColor.White,
    color: CCCColor.YouTubeRed,
  },
});

export function YouTubeButton({
  numberOfVideos,
  onClick,
  disabled = false,
}: IProps) {
  const classes = useStyles();
  const text = `${numberOfVideos} YouTube Video${
    numberOfVideos === 1 ? '' : 's'
  }`;
  return (
    <Button
      variant="contained"
      startIcon={<PlayArrow />}
      className={classes.button}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}
