import React, { Component } from 'react';
import { StyleRulesCallback, Theme, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import IProduct from '../../../../functions/src/models/Product';
import AppSettings from '../../../AppSettings';
import AmazonLink from './AmazonLink';

const styles: StyleRulesCallback<Theme, {}> = (/*theme*/) => ({
  button: {
    textTransform: 'none',
    backgroundColor: '#febd69',
  },
});

interface IProps {
  product: IProduct;
  priceCategory: string;

  className?: string;

  classes: { [key: string]: string };
}

class AmazonButton extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const { product, priceCategory, className, classes } = this.props;

    return (
      <AmazonLink
        product={product}
        priceCategory={priceCategory}
        trackingOrigin="amazon-button"
        className={className}
      >
        <Button
          variant="contained"
          startIcon={<ShoppingCart />}
          className={classes.button}
        >
          {AppSettings.abTesting
            ? 'bei Amazon ansehen'
            : 'bei Amazon bestellen'}
        </Button>
      </AmazonLink>
    );
  }
}

export default withStyles(styles)(AmazonButton);
