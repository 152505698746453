import React, { Component } from 'react';
import { ILoadingInformation } from './models';
import { Typography } from '@material-ui/core';

interface IProps {
  information: ILoadingInformation;
}
export class Loader extends Component<IProps> {
  render() {
    const { information } = this.props;
    return (
      <div className="loader">
        <div className="loader-circles" />
        <div className="loader-text">
          <Typography variant="h5">{information.title}</Typography>
        </div>
        {!!information.description && <div>{information.description}</div>}
      </div>
    );
  }
}
