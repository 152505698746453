import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  withMobileDialog,
} from '@material-ui/core';
import { IYouTubeVideo } from '../../services/YouTubeService';
import { YouTubeVideo } from './YouTubeVideo';

interface IProps {
  videos: IYouTubeVideo[];
  open: boolean;
  onClose: () => void;
}

interface IInjectedWithMobileDialogProps {
  fullScreen: boolean;
}

class DialogComponent extends Component<
  IProps & Partial<IInjectedWithMobileDialogProps>
> {
  constructor(props: IProps) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.onClose();
  }

  render() {
    const { videos, open, fullScreen } = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={this.handleClose}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>YouTube Videos</DialogTitle>
        <DialogContent>
          <List>
            {videos.map(video => (
              <ListItem key={video.videoId}>
                <YouTubeVideo videoId={video.videoId} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            schließen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const YouTubeVideosDialog = withMobileDialog<IProps>()(DialogComponent);
