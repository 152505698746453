/** Provides helper logic to interact with basic DOM containers like html and body. */

enum BodyClasses {
  OverflowScroll = 'ccc-overflow-scroll',
}

/** Enforces scrollbar on body (overflow: scroll). */
export function overflowScroll() {
  document
    .getElementsByTagName('body')[0]
    .classList.add(BodyClasses.OverflowScroll);
}
/** Resets scrollbar behavior on body. */
export function overflowReset() {
  document
    .getElementsByTagName('body')[0]
    .classList.remove(BodyClasses.OverflowScroll);
}

enum HtmlClasses {
  BrightBackground = 'ccc-bright-background',
}

export function brightBackgroundImage() {
  document
    .getElementsByTagName('html')[0]
    .classList.add(HtmlClasses.BrightBackground);
}
export function resetBackgroundImage() {
  document
    .getElementsByTagName('html')[0]
    .classList.remove(HtmlClasses.BrightBackground);
}
