import React, { Component, ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { StyleRules, withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const styles: StyleRules<string> = {};

interface IProps {
  children: {
    button: ReactNode;
    title: ReactNode;
    content: ReactNode;
  };
}

interface IState {
  open: boolean;
}

class ResponsiveDialog extends Component<
  IProps & { fullScreen?: boolean },
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  setOpen(open: boolean) {
    this.setState({ open });
  }

  render() {
    const { fullScreen } = this.props;
    const { title, content, button } = this.props.children;
    return (
      <p>
        <Button
          variant="text"
          color="primary"
          onClick={() => this.setOpen(true)}
        >
          {button}
        </Button>
        <Dialog
          fullScreen={fullScreen}
          open={this.state.open}
          onClose={() => this.setOpen(false)}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{content}</DialogContent>
          <DialogActions>
            <Button onClick={() => this.setOpen(false)} color="primary">
              schließen
            </Button>
          </DialogActions>
        </Dialog>
      </p>
    );
  }
}

export default withStyles(styles)(withMobileDialog<IProps>()(ResponsiveDialog));
