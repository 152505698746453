import React from 'react';
import IProductSearchResult from '../../../../functions/src/models/ProductSearchResult';
import { withStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';
import IBrand from '../../../../functions/src/models/Brand';
import ReactWordcloud, { Word, OptionsProp } from 'react-wordcloud';
import { theme } from '../../styles/theme';

interface IProps {
  searchResults: IProductSearchResult;
  classes: { [key: string]: string };
}

const styles: StyleRules = {
  chartContainer: {
    margin: 'auto',
    maxWidth: 'calc(100vw - 40px)', // asserts that chart doesnt leave the view on small devices
  },
};

function mapBrandsToWordCloudData(brands: IBrand[]): Word[] {
  return brands.map(brand => ({
    text: brand.name,
    value: brand.productCount,
  }));
}

const SearchResultsBrandsWordCloud = React.memo(
  ({ classes, searchResults }: IProps) => {
    const words = mapBrandsToWordCloudData(searchResults.brands);

    const options: OptionsProp = {
      rotations: 1,
      rotationAngles: [0, 90],
      fontSizes: [25, 75],
      fontFamily: theme.typography.fontFamily,
    };

    return (
      <div className={classes.chartContainer}>
        <ReactWordcloud words={words} options={options} />
      </div>
    );
  }
);

export default withStyles(styles)(SearchResultsBrandsWordCloud);
