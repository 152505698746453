import React from 'react';
import { makeStyles } from '@material-ui/core';

interface IProps {
  videoId: string;
}

const useStyles = makeStyles({
  iframe: {
    border: 'none',
  },
});

export function YouTubeVideo({ videoId }: IProps) {
  const src = `https://www.youtube.com/embed/${videoId}`;
  const classes = useStyles();
  return (
    <iframe
      width="100%"
      height="300"
      src={src}
      className={classes.iframe}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  );
}
