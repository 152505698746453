import React, { Component } from 'react';
import { withStyles, StyleRules } from '@material-ui/core/styles';

import { Tooltip } from '@material-ui/core';

const styles: StyleRules<string> = {
  tooltip: {
    fontSize: '1.25em',
    textAlign: 'justify',
  },
};

interface IProps {
  title: string;
  body: React.ReactNode;
  children: React.ReactElement<any>;

  classes: { [key: string]: string };
}

class DetailsTooltip extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const { title, body, children, classes } = this.props;
    return (
      <Tooltip
        placement="right"
        title={
          <div className={classes.tooltip}>
            <h3>{title}</h3>
            {body}
          </div>
        }
      >
        {children}
      </Tooltip>
    );
  }
}

export default withStyles(styles)(DetailsTooltip);
