import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';
import Footer from './shared/components/Footer';
import { MuiThemeProvider } from '@material-ui/core';
import { theme } from './shared/styles/theme';

ReactDOM.render(
  <Router>
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <div>
          <Switch>
            <Route
              path="/s/:query?"
              render={({ match, history }) => (
                <App defaultQuery={match.params.query} history={history} />
              )}
            />
            <Redirect to="/s" />
          </Switch>
          <Footer />
        </div>
      </SnackbarProvider>
    </MuiThemeProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
