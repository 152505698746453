import React, { Component, ReactNode } from 'react';
import { Typography } from '@material-ui/core';

interface IProps {
  children: ReactNode;
}

class SectionHeader extends Component<IProps> {
  render() {
    const { children } = this.props;
    return (
      <Typography
        color="secondary"
        variant="h5"
        paragraph={true}
        align="left"
        className="ccc-typography-lato"
      >
        {children}
      </Typography>
    );
  }
}

export default SectionHeader;
