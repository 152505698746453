import React, { Component } from 'react';
import { withStyles, StyleRules } from '@material-ui/core/styles';
import {
  ProductCategory,
  getCategoryName,
  getCategoryColor,
} from '../searchResultsChart/models';
import { formatAsCurrency } from '../core/format';

const styles: StyleRules = {
  categoryName: {
    padding: '4px 6px',
    borderRadius: '4px',
    color: '#fff',
  },
  priceRangeValue: {
    fontWeight: 'bold',
  },
  interactiveText: {
    transition: 'all .5s',
  },
  interactiveTextActivate: {
    backgroundColor: 'rgba(0,0,0,.2)',
    padding: '2px 2px',
    borderRadius: '2px',
    '& $categoryName': {
      fontWeight: 'bold',
    },
  },
};

interface IProps {
  category: ProductCategory;
  classes: { [key: string]: string };
  isActive: boolean;
  priceRange: { min: number; max: number };
}

class ProductCategoryPriceRange extends Component<IProps> {
  render() {
    const { classes, category, priceRange, isActive } = this.props;

    let className = classes.interactiveText;
    if (isActive) {
      className += ` ${classes.interactiveTextActivate}`;
    }
    return (
      <span className={className}>
        Die Preise in der Kategorie{' '}
        <span
          className={classes.categoryName}
          style={{ backgroundColor: getCategoryColor(category) }}
        >
          {getCategoryName(category)}
        </span>{' '}
        liegen zwischen{' '}
        <span className={classes.priceRangeValue}>
          {formatAsCurrency(priceRange.min)}
        </span>{' '}
        und{' '}
        <span className={classes.priceRangeValue}>
          {formatAsCurrency(priceRange.max)}
        </span>
        .
      </span>
    );
  }
}

export default withStyles(styles)(ProductCategoryPriceRange);
