import { IAppSettings } from './shared/models';
import { getQueryParameter } from './shared/utils/UriUtils';

let settings: IAppSettings = {
  fakeDelay: 8 * 1000, // should be in line with opacity transition in index.scss
  searchResults: {
    showReleaseDateIndicator: false,
  },

  abTesting: getQueryParameter('ab', window.location.href) === 'b',
};

/***
 * devs can overwrite all settings with an AppSettings.dev.tsx
 * (which is in .gitignore so everbody can have their own)
 */
if (process.env.NODE_ENV !== 'production') {
  try {
    const devSettings = require('./AppSettings.dev').default;

    settings = { ...settings, ...devSettings };

    console.debug('Loaded dev settings from AppSettings.dev.tsx');
  } catch {
    // ignore AppSettings.dev.tsx if it doesn't exist
  }
}

export default settings;
