import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  div: {
    overflow: 'hidden',
    width: '240px',
    height: '15px',
    display: 'inline-block',
  },
  iframe: {
    'pointer-events': 'none',
    'margin-top': '-118px',
    'margin-left': '-10px',
  },
};

interface IProps {
  iFrameUrl: string;
  detailsUrl: string;

  classes: { [key: string]: string };
}

class ReviewsBox extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const { detailsUrl, iFrameUrl, classes } = this.props;
    return (
      <a href={detailsUrl} target="_blank">
        <div className={classes.div}>
          <iframe
            className={classes.iframe}
            src={iFrameUrl}
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            scrolling="no"
            tabIndex={-1}
          />
        </div>
      </a>
    );
  }
}

export default withStyles(styles)(ReviewsBox);
