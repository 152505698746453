import React, { Component } from 'react';
import { withStyles, StyleRules } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { CCCColor } from '../../styles/colors';

const styles: StyleRules<string> = {
  container: {
    display: 'inline-block',
    color: CCCColor.Grey,
    // background: CCCColor.Green,
    background: CCCColor.Blue,
    // background:
    //   'linear-gradient(to right, #039be5 40px, #fff 2px, #039be5 42px)',
    borderRadius: '6px',
    padding: '4px 8px',
    '&::after': {
      clear: 'both',
      content: '""',
      display: 'block',
    },
  },
  icon: {
    float: 'left',
  },
  text: {
    float: 'right',
    marginLeft: '4px',
    // marginLeft: '16px',
  },
};

interface IProps {
  text: string;
  classes: { [key: string]: string };
}

class FeatureBadge extends Component<IProps> {
  render() {
    const { classes, text } = this.props;

    return (
      <div className={classes.container}>
        <FavoriteIcon className={classes.icon} />
        <div className={classes.text}>{text}</div>
      </div>
    );
  }
}

export default withStyles(styles)(FeatureBadge);
