import { Component } from 'react';
import { interval } from 'rxjs';
import { map, takeWhile, startWith } from 'rxjs/operators';
import { ILoadingInformation } from './models';

export function trackPromiseWithLoadingInformation<T>(
  component: Component<{}, { loadingInformation?: ILoadingInformation }>,
  promise: Promise<T>,
  {
    fakeDelay = 0,
    texts = [{ title: 'Loading' }],
  }: {
    /** Assert that the promise wont resolve before this delay. */
    fakeDelay?: number;
    /** A list of texts that should be shown in regular intervals during the fakeDelay. */
    texts?: ILoadingInformation[];
  } = {}
) {
  component.setState({ loadingInformation: { title: 'Loading' } });

  const promises: [Promise<T>, Promise<ILoadingInformation>] = [
    promise,
    Promise.resolve({ title: 'Loading' }),
  ];

  if (fakeDelay) {
    // observable that will emit passed texts over time
    const textUpdater = interval(fakeDelay / texts.length).pipe(
      map(v => v + 1),
      takeWhile(v => v < texts.length),
      map(v => texts[v]),
      startWith(texts[0])
    );

    textUpdater.subscribe(loadingInformation =>
      component.setState({ loadingInformation })
    );
    promises.push(textUpdater.toPromise());
  }

  return Promise.all(promises).then(
    ([result]) => {
      component.setState({ loadingInformation: undefined });
      return result;
    },
    err => {
      component.setState({ loadingInformation: undefined });
      return Promise.reject(err);
    }
  );
}
