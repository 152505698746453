declare const gapi: any;

const API_KEY = 'AIzaSyCX0XLckiXyqQQrmkxlOvd46OW5IlnqHXc';

export interface IYouTubeVideo {
  videoId: string;
}

class YouTubeService {
  private constructor() {
    this.loadClientPromise = this.loadClient();
  }

  public static instance = new YouTubeService();

  searchForVideos(query: string): Promise<Array<IYouTubeVideo>> {
    return this.loadClientPromise.then(() =>
      gapi.client.youtube.search
        .list({
          part: 'snippet',
          maxResults: 25,
          q: query,
        })
        .then((response: any) =>
          response.result.items.map((item: { id: { videoId: string } }) => ({
            videoId: item.id.videoId,
          }))
        )
    );
  }

  private loadClientPromise: Promise<void>;

  private loadClient() {
    return new Promise<void>((resolve, reject) => {
      gapi.load('client', () => {
        gapi.client.setApiKey(API_KEY);
        return gapi.client
          .load('https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest')
          .then(
            () => resolve(),
            (err: any) => {
              console.warn('Error loading GAPI client for API', err);
              reject(err);
            }
          );
      });
    });
  }
}

export const youTubeService = YouTubeService.instance;
