import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ResponsiveDialog from './core/ResponsiveDialog';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {};

interface IProps {
  classes: { [key: string]: string };
}

interface IState {}

class SearchBoxDescription extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div>
          Geben Sie das gesuchte Produkt ein - um den Rest kümmern wir uns.
        </div>
        <ResponsiveDialog>
          {{
            button: 'Wie funktioniert das?',
            title: 'Wie funktioniert etalgo?',
            content: (
              <p>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>
                      1. Internet nach Produkten durchsuchen
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <p>
                        Die Etalgo Server gehen auf die Suche und sammeln
                        Informationen über das gesuchte Produkt. Wie wenn Sie in
                        der Einkaufsstraße in Schaufenster schauen oder durch
                        Läden stöbern.{' '}
                      </p>
                      <p>
                        Genau wie Sie, macht sich Etalog ein Bild davon welche
                        Eigenschaften wichtig sind. Der Clou: Etalgo hat ein
                        fotografisches Gedächtnis und ist blitzschnell.
                      </p>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>
                      2. Ergebnisse analysieren
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      Das von Ihnen gesuchte Produkt wird zunächst
                      kategorisiert, bevor wir die Preisanalyse starten. Obwohl
                      sich Preise, Produkte und Bewertungen im Internet täglich
                      ändern, dauert die Suche nur wenige Sekunden.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>
                      3. Kategorien und Sieger ermitteln
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      Wir analysieren die{' '}
                      <a href="https://etalgo.com/" target="_blank">
                        Preisstruktur
                      </a>{' '}
                      der gefundenen Produkte und ermitteln nach{' '}
                      <a href="https://etalgo.com/" target="_blank">
                        objektiven Kriterien
                      </a>{' '}
                      das beste Produkt in jeder Kategorie. Die Platzierungen
                      werden nicht durch aktuelle Werbemaßnahmen der Anbieter
                      beeinflusst. Das beste Produkt ist somit neutral und
                      objektiv anhand der{' '}
                      <a href="https://etalgo.com/" target="_blank">
                        Bewertungskriterien
                      </a>{' '}
                      als Sieger hervorgegangen.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </p>
            ),
          }}
        </ResponsiveDialog>
      </div>
    );
  }
}

export default withStyles(styles)(SearchBoxDescription);
