import React, { Component, ReactNode } from 'react';
import { Typography } from '@material-ui/core';

interface IProps {
  children: ReactNode;
}

class SectionParagraph extends Component<IProps> {
  render() {
    const { children } = this.props;
    return (
      <Typography variant="subtitle1" align="left">
        {children}
      </Typography>
    );
  }
}

export default SectionParagraph;
