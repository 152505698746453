import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import IProductSearchResult from '../../../../functions/src/models/ProductSearchResult';
import IProduct from '../../../../functions/src/models/Product';
import { getCategoryColor, getCategoryName } from './models';

interface IProps {
  searchResults: IProductSearchResult;
  highlightedCategory?: string;
}
interface IState {
  options: any;
  series: any;
}

class SearchResultsChart2 extends Component<IProps, IState> {
  constructor(props: IProps, state: IState) {
    super(props, state);

    const toSeries = (name: string, products: IProduct[]) => ({
      name,
      data: products.map(p => [p.price, p.rawSalesRank]),
    });

    const series = [
      toSeries(getCategoryName('cheap'), props.searchResults.products.cheap),
      toSeries(getCategoryName('choice'), props.searchResults.products.choice),
      toSeries(getCategoryName('crazy'), props.searchResults.products.crazy),
    ];

    // round min/max values of x-axis to have naturally looking even start/end price
    const xAllValues = series.flatMap(x => x.data.map(y => y[0]));
    const xMin = Math.floor(Math.min(...xAllValues) / 10) * 10;
    const xMax = Math.ceil(Math.max(...xAllValues) / 10) * 10;

    // leave some space to y min (=top end of the chart) since otherwise top most entry is cut off
    const yAllValues = series.flatMap(x => x.data.map(y => y[1]));
    const yMin = Math.floor(Math.min(...yAllValues) / 100) * 100;

    this.state = {
      options: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          tickAmount: 10,
          min: xMin,
          max: xMax,
          labels: {
            formatter: function(val: any) {
              return val + ' €';
            },
          },
          tooltip: {
            enabled: false,
          },
          crosshairs: {
            show: false,
          },
        },
        yaxis: {
          min: yMin,
          show: true,
          reversed: true,
          // logarithmic since Amazon's PA API 4.0 sales ranks are global and therefore have a huge range von 1 to 100_000 and more
          // and we should put emphasis on differences in the lower sector as it is important to see if a product is rank 1 or 100 and not so important if it's rank 10_000 or 20_000
          logarithmic: true,
          title: {
            text: 'Beliebtheit',
          },
          labels: {
            show: false,
          },
        },
        colors: [
          getCategoryColor('cheap'),
          getCategoryColor('choice'),
          getCategoryColor('crazy'),
        ],
        // tooltip: {
        //   y: {
        //     formatter: (_: number, __: any) => <div>JOOO</div>,
        //     title: {
        //       formatter: (_: string) => '',
        //     },
        //   },
        // },
        // legend: {
        //   onItemClick: {
        //     toggleDataSeries: false,
        //   },
        // },
      },
      series: series,
    };
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="scatter"
      />
    );
  }
}

export default SearchResultsChart2;
