import React from 'react';
import IProductSearchResult from '../../../functions/src/models/ProductSearchResult';
import { withStyles } from '@material-ui/core';
// import SearchResultsChart from './searchResultsChart/SearchResultsChart';
import SearchResultsChart2 from './searchResultsChart/SearchResultsChart2';
import { SectionHeader, SectionParagraph } from './core/typography';
import Section from './Section';
import { getAllProducts } from '../models/dto/ProductSearchResultUtils';
import ProductCategoryPriceRange from './searchResults/ProductCategoryPriceRange';
import { getCategories, ProductCategory } from './searchResultsChart/models';
import image from '../../assets/analysis.jpg';
import { StyleRules } from '@material-ui/core/styles';
import { SearchResultsBrandsVisualization } from './searchResultsBrandsVisualization';
import { getPriceRange } from '../utils/ProductUtils';
import { formatAsCurrency } from './core/format';

export const ExplanationSectionId = 'explanation';

interface IProps {
  searchQuery: string;
  searchResults: IProductSearchResult;
  classes: { [key: string]: string };
}
interface IState {
  highlightedCategory?: ProductCategory;
}

const styles: StyleRules = {
  image: {
    width: '90%',
  },
};

class SearchResultsVisualizationSection extends React.Component<
  IProps,
  IState
> {
  state: IState = {};

  render() {
    const { searchResults, searchQuery, classes } = this.props;
    const { highlightedCategory } = this.state;
    const priceRanges = this.buildPriceRanges(searchResults);
    return (
      <div>
        <Section>
          {{
            content: (
              <SearchResultsChart2
                searchResults={searchResults}
                highlightedCategory={highlightedCategory}
              ></SearchResultsChart2>
              // <SearchResultsChart
              //   searchResults={searchResults}
              //   highlightedCategory={highlightedCategory}
              // />
            ),
            description: (
              <div>
                <SectionHeader>etalgo Preisanalyse</SectionHeader>
                <SectionParagraph>
                  Behalten Sie den Überblick mit der etalgo Preisanalyse! Die
                  Preise für "{searchQuery}" liegen zwischen{' '}
                  {formatAsCurrency(priceRanges.all.min)} und{' '}
                  {formatAsCurrency(priceRanges.all.max)}.
                  {getCategories().map(category => (
                    <span
                      key={category}
                      onMouseEnter={() => {
                        this.stopAutoCategoryHighlighting();
                        this.highlightCategory(category);
                      }}
                      onMouseLeave={() => this.highlightCategory()}
                    >
                      {' '}
                      <ProductCategoryPriceRange
                        isActive={category === this.state.highlightedCategory}
                        category={category}
                        priceRange={priceRanges[category]}
                      />
                    </span>
                  ))}
                </SectionParagraph>
              </div>
            ),
          }}
        </Section>
        <Section contentRight={true}>
          {{
            content: (
              <SearchResultsBrandsVisualization
                searchResults={searchResults}
              ></SearchResultsBrandsVisualization>
            ),
            description: (
              <div>
                <SectionHeader>etalgo Markenanalyse</SectionHeader>
                <SectionParagraph>
                  Die Word-Cloud bietet Ihnen einen Überblick der wichtigsten
                  Marken zum Suchbegriff "{searchQuery}". Je größer der Text
                  desto mehr Artikel bietet die entsprechende Marke.
                </SectionParagraph>
              </div>
            ),
          }}
        </Section>
        <Section>
          {{
            content: (
              <img
                src={image}
                className={classes.image}
                alt="Analyse Infografik"
              />
            ),
            description: (
              <div id={ExplanationSectionId}>
                <SectionHeader>Testberichte lesen war gestern!</SectionHeader>
                <SectionParagraph>
                  Mehr als die Hälfte der Online-Shopper verbringt über 75% der
                  Zeit mit der Produktrecherche! Mit etalgo finden Sie ihr
                  Wunschprodukt innerhalb weniger Sekunden.
                  <ol>
                    <li>
                      Wir sammeln und werten für Sie alle Informationen über das
                      gewünschte Produkt aus.
                    </li>
                    <li>
                      Die gefundenen Produkte werden nach objektiven Kriterien
                      analysiert und in Preiskategorien eingeteilt.
                    </li>
                    <li>
                      Innerhalb weniger Sekunden sehen Sie das Sieger-Produkt in
                      jeder Preiskategorie und können so direkt das beste Modell
                      bestellen.
                    </li>
                  </ol>
                </SectionParagraph>
              </div>
            ),
          }}
        </Section>
      </div>
    );
  }

  /***
   * Auto-highlight categories until user interacts with them.
   */
  private currentCategoryIndex = 0;
  private categoryInterval: NodeJS.Timeout | undefined = setInterval(() => {
    this.currentCategoryIndex =
      (this.currentCategoryIndex + 1) % getCategories().length;
    this.highlightCategory(getCategories()[this.currentCategoryIndex]);
  }, 5 * 1000);

  private buildPriceRanges(searchResults: IProductSearchResult) {
    return {
      all: getPriceRange(getAllProducts(searchResults)),
      cheap: getPriceRange(searchResults.products.cheap),
      choice: getPriceRange(searchResults.products.choice),
      crazy: getPriceRange(searchResults.products.crazy),
    };
  }

  private highlightCategory(category: ProductCategory | undefined = undefined) {
    this.setState({ highlightedCategory: category });
  }

  private stopAutoCategoryHighlighting() {
    if (this.categoryInterval) {
      clearInterval(this.categoryInterval);
      this.categoryInterval = undefined;
    }
  }
}
export default withStyles(styles)(SearchResultsVisualizationSection);
