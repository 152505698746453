import React, { Component } from 'react';
import { withStyles, StyleRules } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import IProduct from '../../../../functions/src/models/Product';
import { formatAsCurrency } from '../core/format';

const styles: StyleRules = {
  container: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  reducedPrice: {
    color: '#B12704',
  },
  listPrice: {
    textDecoration: 'line-through',
  },
};

interface IProps {
  product: IProduct;
  classes: { [key: string]: string };
}

class PriceCategory extends Component<IProps> {
  render() {
    const { product, classes } = this.props;
    return (
      <div className={classes.container}>
        {!!product.listPrice && product.listPrice !== product.price && (
          <Typography
            className={classes.listPrice}
            variant="h6"
            color="textSecondary"
          >
            {formatAsCurrency(product.listPrice)}
          </Typography>
        )}
        <Typography
          className={
            !!product.listPrice && product.listPrice !== product.price
              ? classes.reducedPrice
              : classes.price
          }
          variant="h4"
          color="textPrimary"
        >
          {formatAsCurrency(product.price)}
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(PriceCategory);
