import React, { Component } from 'react';
import IProduct from '../../../../functions/src/models/Product';

interface IProps {
  product: IProduct;
  allProducts: IProduct[];
}

class SalesRankIndicator extends Component<IProps> {
  state: {
    value: number;
    minYear: number;
    maxYear: number;
    percent: number;
  };

  constructor(props: IProps) {
    super(props);

    const getYear = (product: IProduct) =>
      new Date(product.details.releaseDate || 0).getFullYear();

    const value = getYear(props.product);

    const productYears = props.allProducts
      .filter(p => !!p.details.releaseDate)
      .map(getYear);
    const minYear = Math.min(...productYears);
    const maxYear = Math.max(...productYears);

    this.state = {
      value: value,
      minYear: minYear,
      maxYear: maxYear,
      percent: Math.round(((value - minYear) / (maxYear - minYear)) * 100),
    };
  }

  render() {
    return <div>Erscheinungsjahr: {this.state.value}</div>;
  }
}

export default SalesRankIndicator;
