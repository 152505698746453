import React, { Component, CSSProperties } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { sanitizeQuery } from '../services/SearchService';

const styles = {
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box' as CSSProperties['boxSizing'],
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
};

interface IProps {
  classes: { [key: string]: string };
  onChange: (value: string) => void;
  defaultQuery?: string;
}

interface IState {
  query: string;
}

class SearchBox extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      query: props.defaultQuery || '',
    };
  }

  componentDidUpdate(prevProps: IProps) {
    // update query if defaultQuery prop changes, e.g. when user navigates forwards/backwards
    // but only update query if it's a real case-insensitive change compared to the current state,
    // since on every search we adjust the page's URL to reflect the search in lowercase,
    // which triggers a query update and would replace the actual query with its lowercase pendant
    if (
      !!this.props.defaultQuery &&
      this.props.defaultQuery !== prevProps.defaultQuery &&
      (!this.state.query ||
        sanitizeQuery(this.props.defaultQuery) !==
          sanitizeQuery(this.state.query))
    ) {
      this.setState({ query: this.props.defaultQuery });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <form>
        <Paper className={classes.root} elevation={1}>
          <InputBase
            className={classes.input}
            placeholder="Nach Produkt suchen, wie z.B. Toaster"
            onChange={this.inputChanged}
            value={this.state.query}
            autoFocus
          />
          <IconButton
            className={classes.iconButton}
            aria-label="Suche"
            type="submit"
            onClick={e => {
              e.preventDefault();
              this.props.onChange(this.state.query);
            }}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </form>
    );
  }

  private inputChanged = (event: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ query: event.target.value });
}

export default withStyles(styles)(SearchBox);
