import React, { Component } from 'react';
import { History } from 'history';
import './App.scss';
import SearchBox from './shared/components/SearchBox';
import SearchBoxDescription from './shared/components/SearchBoxDescription';
import * as SearchService from './shared/services/SearchService';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import SearchResults from './shared/components/SearchResults';
import { trackPromiseWithLoadingInformation } from './shared/components/core/loader';
import { Grid, Typography } from '@material-ui/core';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import IProductSearchResult from '../functions/src/models/ProductSearchResult';
import { Loader, ILoadingInformation } from './shared/components/core/loader';
import * as LocationService from './shared/services/LocationService';
import * as ViewService from './shared/services/ViewService';
import DetailsTooltip from './shared/components/DetailsTooltip';
import SearchResultsVisualizationSection from './shared/components/SearchResultsVisualizationSection';
import AppSettings from './AppSettings';

interface IProps extends WithSnackbarProps {
  defaultQuery?: string;
  history: History;
}

interface IState {
  searchQuery?: string;
  searchResults?: IProductSearchResult;
  loadingInformation?: ILoadingInformation;
}

const INITIAL_STATE: IState = {
  searchQuery: undefined,
  searchResults: undefined,
  loadingInformation: undefined,
};

class App extends Component<IProps, IState> {
  query$ = new BehaviorSubject('');
  subManager = new Subscription();

  constructor(props: IProps) {
    super(props);
    const { enqueueSnackbar } = this.props;

    this.search = this.search.bind(this);

    this.state = INITIAL_STATE;

    this.subManager.add(
      this.query$
        .pipe(
          tap(query =>
            query
              ? ViewService.brightBackgroundImage()
              : ViewService.resetBackgroundImage()
          ),
          filter(query => !!query),
          map(SearchService.sanitizeQuery)
        )
        .subscribe(query => {
          console.debug('Searching ', query);

          // avoids scrollbar flicker during animation on initial search (certain screen sizes)
          ViewService.overflowScroll();

          LocationService.navigateTo(
            ['s', query.toLowerCase()],
            this.props.history
          );

          this.setState({ searchQuery: query, searchResults: undefined });
          trackPromiseWithLoadingInformation(
            this,
            SearchService.search(query),
            {
              fakeDelay: AppSettings.fakeDelay,
              texts: [
                {
                  title: 'Searching products',
                  description:
                    'Wussten Sie, dass sich Preise im Durchschnitt alle 10 Minuten ändern?',
                },
                {
                  title: 'Analyzing matches',
                  description:
                    'Mehr als die Hälfte der Online-Shopper verbringt über 75% der Zeit mit der Produktrecherche.',
                },
                {
                  title: 'Preparing results',
                  description:
                    'Wir vergleichen alle Produkte und identifizieren die Besten.',
                },
              ],
            }
          )
            .then(searchResults => {
              console.debug('Search Result', searchResults);

              this.setState({ searchResults });
            })
            .catch(err => enqueueSnackbar(err, { variant: 'error' }));
        })
    );
  }

  search(query: string) {
    this.query$.next(query);
  }

  componentDidMount() {
    // directly search for the query prop when the component is mounted
    // necessary to trigger search when the user follows a deep link like spoil.world/s/toaster
    if (this.props.defaultQuery !== undefined) {
      this.search(this.props.defaultQuery);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    // do another search when query prop is updated
    // necessary to trigger search when user navigates forwards/backwards

    if (this.props.defaultQuery !== prevProps.defaultQuery) {
      if (this.props.defaultQuery !== undefined) {
        this.search(this.props.defaultQuery);
      } else {
        this.setState(INITIAL_STATE);
        ViewService.resetBackgroundImage();
      }
    }
  }

  componentWillUnmount() {
    this.subManager.unsubscribe();
    ViewService.overflowReset();
    ViewService.resetBackgroundImage();
  }

  get showResultContainer() {
    return !!this.state.loadingInformation || this.state.searchResults;
  }

  render() {
    return (
      <div className="App">
        <div
          className={
            this.showResultContainer
              ? 'App-search-box'
              : 'App-search-box App-search-box--centered'
          }
        >
          {!this.showResultContainer && (
            <h2 className="App-search-box-title">
              Testberichte lesen war gestern!
            </h2>
          )}
          <div className="App-search-box-input">
            <SearchBox
              onChange={this.search}
              defaultQuery={this.props.defaultQuery}
            />
          </div>
          {!this.showResultContainer && <SearchBoxDescription />}
          {this.state.searchResults && !!this.state.searchResults.warning && (
            <DetailsTooltip
              title="Suchergebnis nicht optimal?"
              body={
                <div>
                  <p>
                    In Ausnahmefällen kann es vorkommen, dass wir eine
                    Suchanfrage nicht in der Qualität beantworten können wie wir
                    es gerne würden.
                  </p>
                  <p>
                    In diesen Fällen können Sie die präsentierten Produkte wie
                    gewohnt anschauen und vergleichen. Sie sollten allerdings im
                    Hinterkopf behalten, dass das Ergebnis und unsere
                    Empfehlungen von unserem gewohnten Standard abweichen
                    könnten.
                  </p>
                  <p>
                    Dies kann verschiedenste Gründe haben. Beispielsweise kann
                    es sein, dass uns für die Suchanfrage einfach noch zu wenige
                    Daten für eine gründliche Analyse vorliegen.
                  </p>
                  <p>
                    Wir informieren Sie immer wenn wir denken, dass ein
                    Suchergebnis nicht unseren gewohnten Standards entspricht.
                    Wir wollen Ihnen den besten Service bieten damit Sie
                    fundierte Kaufentscheidungen treffen können. Und dazu gehört
                    zuzugeben, wenn etwas noch Verbesserung bedarf. Das gehört
                    zu unserer maximalen Transparenz Strategie für die uns
                    unsere Nutzer so schätzen.
                  </p>
                </div>
              }
            >
              <Typography
                color="error"
                variant="subtitle1"
                style={{ marginTop: '10px', verticalAlign: 'middle' }}
              >
                {this.state.searchResults.warning} 😞
              </Typography>
            </DetailsTooltip>
          )}
        </div>
        {this.showResultContainer && (
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
          >
            {!!this.state.loadingInformation ? (
              <Loader information={this.state.loadingInformation} />
            ) : (
              this.state.searchQuery &&
              this.state.searchResults && (
                <div>
                  <div className="App-results">
                    <SearchResults
                      searchQuery={this.state.searchQuery}
                      searchResults={this.state.searchResults}
                    />
                  </div>
                  <div className="App-horizontal-section">
                    <div
                      className="App-horizontal-section-content"
                      style={{ marginTop: '-200px' }}
                    >
                      <SearchResultsVisualizationSection
                        searchQuery={this.state.searchQuery}
                        searchResults={this.state.searchResults}
                      />
                    </div>
                  </div>
                </div>
              )
            )}
          </Grid>
        )}
      </div>
    );
  }
}

export default withSnackbar(App);
