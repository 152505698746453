import React, { Component } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  withMobileDialog,
} from '@material-ui/core';
import { withStyles, StyleRules } from '@material-ui/core/styles';
import IProduct from '../../../functions/src/models/Product';
import ReviewsBox from './searchResults/ReviewsBox';
import ReleaseDateIndicator from './searchResults/ReleaseDateIndicator';
import AppSettings from '../../AppSettings';
import AmazonButton from './searchResults/AmazonButton';
import ProductPrice from './searchResults/ProductPrice';

const styles: StyleRules<string> = {
  productImage: {
    width: '120px',
    height: '160px',
    margin: 'auto',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  details: {
    fontSize: '0.75em',
  },
};

interface IProps {
  priceCategory: string;
  products: IProduct[];
  allProducts: IProduct[];
  searchQuery: string;

  open: boolean;
  onClose: () => void;

  classes: { [key: string]: string };
}

interface IInjectedWithMobileDialogProps {
  fullScreen: boolean;
}

class SearchResultsDialog extends Component<
  IProps & Partial<IInjectedWithMobileDialogProps>
> {
  constructor(props: IProps) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.onClose();
  }

  render() {
    const {
      priceCategory,
      products,
      allProducts,
      open,
      fullScreen,
      classes,
    } = this.props;

    const winner = products[0];

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={this.handleClose}
        maxWidth="md"
      >
        <DialogTitle>Spoil World's {priceCategory}</DialogTitle>
        <DialogContent>
          <List>
            <ListItem key={winner.id}>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h6" color="textPrimary">
                    {winner.name}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item>
                    <div
                      className={classes.productImage}
                      style={{
                        backgroundImage: `url('${winner.image}')`,
                      }}
                    />
                  </Grid>
                  {/* <Grid item>
                    <SalesRankIndicator
                      product={winner}
                      allProducts={allProducts}
                      searchQuery={searchQuery}
                    />
                  </Grid> */}
                  <Grid item>
                    <ProductPrice product={winner} />
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      {winner.reviews && (
                        <Grid item>
                          <ReviewsBox
                            iFrameUrl={winner.reviews.iFrameUrl}
                            detailsUrl={winner.reviews.url}
                          />
                        </Grid>
                      )}
                      {AppSettings.searchResults.showReleaseDateIndicator &&
                        winner.details.releaseDate && (
                          <Grid item>
                            <ReleaseDateIndicator
                              product={winner}
                              allProducts={allProducts}
                            />
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                  <Grid item>
                    <AmazonButton
                      product={winner}
                      priceCategory={priceCategory}
                    />
                  </Grid>
                </Grid>
                <Grid item className={classes.details}>
                  {winner.details && winner.details.features && (
                    <ul>
                      {winner.details.features.map((detail, index) => (
                        <li key={index}>{detail}</li>
                      ))}
                    </ul>
                  )}
                </Grid>
              </Grid>
            </ListItem>

            {products.slice(1).map((product, index) => (
              <ListItem key={product.id}>
                <ListItemText
                  disableTypography
                  primary={
                    <div>
                      {index + 2 + '. Platz'}
                      <br />
                      {product.name}
                    </div>
                  }
                  secondary={
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      spacing={4}
                      style={{ paddingTop: '10px' }}
                    >
                      <Grid item>
                        <div
                          className={classes.productImage}
                          style={{
                            backgroundImage: `url('${product.image}')`,
                          }}
                        />
                      </Grid>
                      {/* <Grid item>
                        <SalesRankIndicator
                          product={product}
                          allProducts={allProducts}
                          searchQuery={searchQuery}
                        />
                      </Grid> */}
                      <Grid item>
                        <ProductPrice product={product} />
                      </Grid>
                      <Grid item>
                        <Grid container direction="column">
                          <Grid item>
                            {product.reviews && (
                              <ReviewsBox
                                iFrameUrl={product.reviews.iFrameUrl}
                                detailsUrl={product.reviews.url}
                              />
                            )}
                          </Grid>
                          {product.details.releaseDate && (
                            <Grid item>
                              <ReleaseDateIndicator
                                product={product}
                                allProducts={allProducts}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <AmazonButton
                          product={product}
                          priceCategory={priceCategory}
                        />
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            schließen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(
  withMobileDialog<IProps>()(SearchResultsDialog)
);
