import * as queryString from 'query-string';

export function getQueryParameter(queryParameter: string, url: string): string {
  const parsedQueryParameter = queryString.parseUrl(url).query[queryParameter];

  if (!parsedQueryParameter) {
    return '';
  } else if (typeof parsedQueryParameter === 'string') {
    return parsedQueryParameter;
  } else {
    return parsedQueryParameter.join(',');
  }
}
