import { CCCColor } from '../../../styles/colors';

export type ProductCategory = 'cheap' | 'choice' | 'crazy';
/** Returns a list of all {@link ProductCategory}. */
export function getCategories(): ProductCategory[] {
  return ['cheap', 'choice', 'crazy'];
}

const CATEGORY_COLOR_MAP: { [category in ProductCategory]: string } = {
  cheap: CCCColor.Orange,
  choice: CCCColor.Green,
  crazy: CCCColor.Red,
};

export function getCategoryColor(category: ProductCategory) {
  return CATEGORY_COLOR_MAP[category];
}

export function getCategoryName(category: ProductCategory) {
  switch (category) {
    case 'cheap':
      return 'Günstig';
    case 'choice':
      return 'Mittelklasse';
    case 'crazy':
      return 'High-End';
  }
}
