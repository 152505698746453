import IProductSearchResult from '../../../functions/src/models/ProductSearchResult';
import * as axios from 'axios';
import { getQueryParameter } from '../utils/UriUtils';
// import { TestData } from '../models/dto/ProductSearchResult.TestData';

export function search(query: string): Promise<IProductSearchResult> {
  // return new Promise((resolve, reject) => {
  //   setTimeout(() => resolve(TestData), 2 * 1000);
  // });
  const debug =
    getQueryParameter('debug', window.location.href) === 'true'
      ? '&debug=true'
      : '';

  return axios.default
    .get<IProductSearchResult | { error: string }>(
      `https://europe-west1-spoil-world-eu.cloudfunctions.net/productSearch?query=${encodeURIComponent(
        query
      )}${debug}`
    )
    .then(result =>
      'error' in result.data
        ? Promise.reject(result.data.error)
        : Promise.resolve(result.data)
    );

  // return callFunction(`productSearch?query=${query}`);
  // return callFunction('productSearch', { query });
  // return new Promise((resolve, reject) => {
  //   setTimeout(
  //     () => resolve([...getTestData(), ...getTestData(), ...getTestData()]),
  //     2 * 1000
  //   );
  // });
}

export function sanitizeQuery(query: string): string {
  return query.trim().toLowerCase();
}
